import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './AboutExperienceSection.css';
import imageSrc from '../../public/assets/aboutme.png'; // Replace with your image path
import HoverButton from '../UIComponents/HoverButton';
import { aboutMeContent } from '../constants'; 
import { Paragraph, Title } from '../ReusableComponents/ReusableComponent';
import HoverCardButton from '../UIComponents/HoverCardButton';


const AboutExperienceSection = () => {
  const navigate = useNavigate(); // Initialize navigate function

  const handleButtonClick = () => {
    navigate('/plans'); // Navigate to /plans on button click
  };

  return (
    <section className="experience-about-section">
      <div className="experience-about-content">
        <div className="experience-about-image">
          <img src={imageSrc} alt="Experience" />
        </div>
        <div className="experience-about-text ">
        
        
         <Title style={{ paddingTop: '0px',  paddingBottom: '20px',}}>About Me</Title>
        <Paragraph style={{marginBottom: '10px'}}>{aboutMeContent.welcome}</Paragraph>
        {/* <Paragraph>{aboutMeContent.certifications}</Paragraph> */}
        <Paragraph style={{marginBottom: '20px'}}>{aboutMeContent.journey}</Paragraph>
        <Paragraph style={{marginBottom: '20px'}}>{aboutMeContent.mission}</Paragraph>
        {/* <p>{aboutMeContent.invitation}</p> */}
        <Paragraph style={{marginBottom: '20px'}}>{aboutMeContent.consultation}</Paragraph>

          <HoverCardButton onClick={() => handleButtonClick()} style={{ width: '100%'}}>
          
          <span>Browse Plans</span>

        </HoverCardButton>
        </div>
      </div>
    </section>
  );
};

export default AboutExperienceSection;
