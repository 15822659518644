import React from 'react';
import Hero from '../components/Hero';
import ExperienceSection from '../components/About/ExperienceSection';
import Content from '../components/Content';
import Webinar from '../components/Webinar';
import Testimonial from '../components/Testimonial';
import ContactUs from '../components/ContactUs';
import DashServices from '../components/DashServices';
import Approach from '../components/Approach';
import FeatureSection from '../components/FeatureSection';
import FAQ from '../components/Contact/FAQ';

const Home = () => {
  return (
    <div>
       <Hero />
       <ExperienceSection/>
       <Approach/>
       <DashServices/>
       <FeatureSection/>
       <Content/>
       <Testimonial/>
       <Webinar/>
       <ContactUs/>
       <FAQ />
    </div>
  );
};


export default Home;
