import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './ExperienceSection.css';
import imageSrc from '../../public/assets/aboutme.png'; // Replace with your image path
import HoverButton from '../UIComponents/HoverButton';
import { Paragraph, Title } from '../ReusableComponents/ReusableComponent';
import HoverCardButton from '../UIComponents/HoverCardButton';

const ExperienceSection = () => {
  const navigate = useNavigate(); // Initialize navigate function

  const handleButtonClick = () => {
    navigate('/plans'); // Navigate to /plans on button click
  };

  return (
    <section className="experience-section">
      <div className="experience-content">
        <div className="experience-image">
          <img src={imageSrc} alt="Experience" />
        </div>
        <div className="experience-text">
          <Title style={{ paddingBottom: '20px' ,}}>Why Consult Sweta Bose</Title>
          <Paragraph  style={{ paddingBottom: '20px' ,}}>
            With over a decade of hands-on experience in the nutrition and wellness industry, I am dedicated to helping you achieve your health goals. I have successfully guided countless clients to a healthier lifestyle through personalized nutrition plans and continuous support.
          </Paragraph>
          <div className="stats">
            <div className="stat">
              <h3>18+</h3>
              <Paragraph>Years of Experience</Paragraph>
            </div>
            <div className="stat">
              <h3>3,000+</h3>
              <Paragraph>Happy Clients</Paragraph>
            </div>
            <div className="stat">
              <h3>98%</h3>
              <Paragraph>Client Satisfaction</Paragraph>
            </div>
            <div className="stat">
              <h3>500+</h3>
              <Paragraph>Healthy Recipes</Paragraph>
            </div>
          </div>
          <HoverCardButton onClick={() => handleButtonClick()} style={{ width: '100%', paddingTop : '20px'}}>
          <span>Browse Plans</span>
        </HoverCardButton>
        </div>
      </div>
    </section>
  );
};

export default ExperienceSection;
