import React from 'react';
import './BlogHeader.css';

const BlogHeader = () => {
  return (
    <header className="blog-header">
      <h1>Nourish Your Life</h1>
      <p>Welcome to Nourish Your Life, your go-to blog for expert nutrition advice, healthy recipes, and tips for a balanced lifestyle. Join us as we explore the latest in nutrition science, delicious meal plans, and ways to achieve your health goals. Let's nourish our bodies and minds together!</p>
    </header>
  );
};

export default BlogHeader;

