import React from 'react';
import './TextStyles.css';

export const Title = ({ children, style }) => {
  return (
    <h1 className="text-title" style={style}>
      {children}
    </h1>
  );
};

export const Subtitle = ({ children, style }) => {
  return (
    <h2 className="text-subtitle" style={style}>
      {children}
    </h2>
  );
};

export const Paragraph = ({ children, style }) => {
  return (
    <p className="text-paragraph" style={style}>
      {children}
    </p>
  );
};
