import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './ServiceDescription.css';
import { serviceData } from '../constants';
import { Paragraph, Subtitle, Title } from '../ReusableComponents/ReusableComponent';

const ServiceDescription = () => {
    const { id } = useParams(); // Get the service id from the URL
    const [selectedService, setSelectedService] = useState(null);

    useEffect(() => {
        // Find the service by id from the serviceData array
        const service = serviceData.find(service => service.id === parseInt(id));
        if (service) {
            setSelectedService(service);
        }
    }, [id]);

    if (!selectedService) {
        return <div>Loading...</div>; // Display a loading message until service is found
    }

    return (
        <div className="service-description-container">
            <Title style={{ padding: '30px', textAlign: 'center' }}>Our Services</Title>
            <div className="service-content">
                <div className="service-list">
                    {serviceData.map(service => (
                        <div
                            key={service.id}
                            className={`service-item ${selectedService.id === service.id ? 'active' : ''}`}
                            onClick={() => setSelectedService(service)}
                        >
                            <Subtitle>{service.title}</Subtitle>
                        </div>
                    ))}
                </div>
                <div className="service-details">
                    <img
                        src={selectedService.image}
                        alt={selectedService.title}
                        className="service-details-image"
                    />
                    <Paragraph>{selectedService.longDescription}</Paragraph>
                    <Paragraph>{selectedService.moreLongDescription}</Paragraph>
                </div>
            </div>
        </div>
    );
};

export default ServiceDescription;
