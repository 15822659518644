import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Content.css'; 
import HoverButton from './UIComponents/HoverButton';
import { Paragraph, Subtitle, Title } from './ReusableComponents/ReusableComponent';
import HoverCardButton from './UIComponents/HoverCardButton';

const Content = () => {

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/plans');
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="hero">
          <img src={`${process.env.PUBLIC_URL}/healthyLife.jpg`} alt="Healthy Life" className="hero-image" />
          <div className="hero-content">
            <Title style={{padding: '20px',}}>Healthy Life</Title>
            <Subtitle style={{ marginBottom: '20px' }}>Lose weight with pleasure, without harming your health!</Subtitle>
            <Paragraph style={{ marginBottom: '30px' }}>
              My primary goal is to help you reduce weight joyfully and safely. An eating plan that promotes health and manages your weight includes a variety of nutritious foods. Add an array of colors to your plate and think of it as eating the rainbow!
            </Paragraph>
            <HoverCardButton onClick={() => handleButtonClick()} style={{ width: '100%'}}>
           <span>  Find a Program </span> 
        </HoverCardButton>
            </div>
        </div>
      </header>
    </div>
  );
};

export default Content;
