import React from 'react';
import './JoinCommunity.css';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { Paragraph, Title } from '../ReusableComponents/ReusableComponent';

const JoinCommunity = () => {
  return (
    <div className="join-community-container">
      <div className="join-community-content">
        {/* <img src="path/to/community-image.jpg" alt="Join our community" /> replace with actual image path */}
        <div className="community-info">
          <Title style={{ paddingBottom:  '20px'}}>Join our Community</Title>
          <Paragraph style={{ paddingBottom:  '20px'}}>Connect with like-minded individuals, share your health journey, and gain access to exclusive nutritional tips and recipes. Our community is here to support you every step of the way.</Paragraph>
          <div className="social-links">
            <a href="https://www.facebook.com" className="social-link" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://www.twitter.com" className="social-link" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a href="https://www.instagram.com/diet_with_sweta/" className="social-link" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinCommunity;
