import React from 'react';
import { Link } from 'react-router-dom';

const BlogCategory = () => {
  return (
    <div>
      <h1>Blog Categories</h1>
      <ul>
        <li><Link to="/blog/category/all">All</Link></li>
        <li><Link to="/blog/category/recipes">Recipes</Link></li>
        <li><Link to="/blog/category/resources">Resources</Link></li>
        <li><Link to="/blog/category/news">News</Link></li>
      </ul>
    </div>
  );
};

export default BlogCategory;
