import React from 'react';
import ContactForm from '../components/Contact/ContactForm';
import JoinCommunity from '../components/Contact/JoinCommunity';
import FAQ from '../components/Contact/FAQ';
import FirstStep from '../components/About/FirstStep';

const Contact = () => {
  return (
    <div>
   <ContactForm />
      <JoinCommunity />
      {/* <FAQ /> */}
      {/* <FirstStep /> */}
    </div>
  );
};

export default Contact;
