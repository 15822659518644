import React, { useState } from 'react';
import { sendEmail } from '../utils/emailUtils'; 
import './ContactUs.css';
import { contactInfo } from './constants';
import { Paragraph, Subtitle, Title } from './ReusableComponents/ReusableComponent';
import HoverCardButton from './UIComponents/HoverCardButton';

const ContactUs = () => {
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    sendEmail(e, resetForm); // Use the sendEmail function with resetForm
  };

  const resetForm = () => {
    setFormState({
      firstName: '',
      lastName: '',
      mobile: '',
      email: '',
      message: ''
    });
  };

  return (
    <div className="contact-us">
      <div className="contact-us-left">
        <div className="hero-contact-info">
        <div className="contact-item">
          <Subtitle style={{marginBottom: '10px', color: '#fff'}}>Email:</Subtitle>
          <Paragraph style={{marginBottom: '20px', color: '#fff'}}>{contactInfo.email}</Paragraph>
         </div>

         <div className="contact-item">
         <Subtitle style={{marginBottom: '10px', color: '#fff'}}>Phone:</Subtitle>
         <Paragraph style={{marginBottom: '20px', color: '#fff'}}>{contactInfo.phone}</Paragraph>
        </div>

        <div className="contact-item">
          <Subtitle style={{marginBottom: '10px', color: '#fff'}}>Timings:</Subtitle>
          <Paragraph style={{marginBottom: '0px', color: '#fff'}}>Monday to Friday, 9:00 AM - 6:00 PM</Paragraph>
         </div>
         
        </div>
      </div>

      <div className="contact-us-form">
        <form onSubmit={handleSubmit}>
          <Title style={{ marginBottom: '20px' }}>Get in Touch</Title>
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              value={formState.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formState.lastName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Mobile</label>
            <input
              type="tel"
              name="mobile"
              value={formState.mobile}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formState.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Your Message</label>
            <textarea
              name="message"
              value={formState.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>

          <HoverCardButton
                 type="submit"
                 style={{ width: '100%' }}
                 >
                 <span>  Get in Touch  </span>
                </HoverCardButton>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;