import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './PlanSingle.css';
import { onlinePlans, homeVisitPlans, DURATIONS, DEFAULT_DURATION, contactInfo } from '../components/constants';
import { Paragraph, Subtitle, Title } from '../components/ReusableComponents/ReusableComponent';
import HoverCardButton from '../components/UIComponents/HoverCardButton';

const PlanSingle = () => {
  const { planId } = useParams();
  const navigate = useNavigate(); // Hook for programmatic navigation

  const allPlans = [...onlinePlans, ...homeVisitPlans];
  const plan = allPlans.find(plan => plan.id === parseInt(planId, 10));

  const [duration, setDuration] = useState(DEFAULT_DURATION);
  const [totalPrice, setTotalPrice] = useState(plan ? parseInt(plan.price.replace('₹', '') || '0', 10) : 0);

  useEffect(() => {
    if (plan) {
      const basePrice = parseInt(plan.price.replace('₹', '') || '0', 10);
      const { multiplier = 1 } = DURATIONS[duration] || {};
      setTotalPrice(basePrice * multiplier);
    }
  }, [duration, plan]);

  const handleDurationChange = (e) => {
    setDuration(e.target.value);
  };

  const handleAddToCart = () => {
    if (plan) {
      navigate('/payment', {
        state: {
          planTitle: plan.title,
          duration,
          totalPrice,
          phoneNumber: contactInfo.mobile, // Example phone number, adjust as needed
          description: plan.description,
          features: plan.features,
          image: plan.image,
        }
      });
    }
  };
  

  if (!plan) {
    return <div className="not-found">Plan not found</div>;
  }

  return (
    <div className="plan-single-container">
      <div className="plan-infos">
        <Title>{plan.title}</Title>
        <Subtitle>Detailed description of the {plan.title} plan.</Subtitle>
        <ul className="plan-features">
          {plan.features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
        <div className="about-plan">
          <Subtitle style={{marginBottom: '10px'}}>About the plan</Subtitle>
          <Paragraph>{plan.description || "This plan offers comprehensive services to meet your needs."}</Paragraph>
        </div>
      </div>
      <div className="plan-card">
        {/* <img src={plan.image} alt={plan.title} className="plan-image" /> */}
        <Subtitle  style={{marginBottom: '30px'}} >Base Price: {plan.price}</Subtitle>
        <div className="plan-duration">
          {/* <label htmlFor="duration">Select plan duration</label>
          <select
            id="duration"
            name="duration"
            value={duration}
            onChange={handleDurationChange}
            aria-label="Select plan duration"
          > */}
            {/* {Object.entries(DURATIONS).map(([key, { label }]) => (
              <option key={key} value={key}>{label}</option>
            ))} */}
          {/* </select> */}
          {duration && (
            <Subtitle style={{marginTop: '30px'}} >Total Price: ₹{totalPrice}</Subtitle>
          )}
        </div>
        <HoverCardButton  onClick={() => handleAddToCart()} 
                style={{marginTop: '20px'}}  >
                <span> Pay Now </span> 
         </HoverCardButton>
        {/* <button className="add-to-cart" onClick={handleAddToCart}>Pay Now</button> */}
      </div>
    </div>
  );
};

export default PlanSingle;
