// src/components/OnlinePlans.js
import React from 'react';
import PlanCard from './PlanCard';
import './Plans.css'; // Reuse the same CSS file for both components
import { onlinePlans } from '../constants';
import { Paragraph, Subtitle, Title } from '../ReusableComponents/ReusableComponent';

const OnlinePlans = () => {
  return (
    <div className="plans-section">
      <Subtitle style={{marginBottom : '20px'}}>Online Consultation Service Plans</Subtitle>
      <Paragraph>
        Explore our range of online consultation service plans, offering personalized nutritional guidance from the comfort of your home. 
        Each plan is tailored to meet your unique needs, with the flexibility to consult with our nutritionist remotely.
      </Paragraph>
      <div className="service-plans">
        {onlinePlans.map((plan) => (
          <PlanCard key={plan.id} plan={plan} />
        ))}
      </div>
    </div>
  );
};

export default OnlinePlans;
