import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import './Footer.css';
import { Paragraph } from './ReusableComponents/ReusableComponent';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="about-section">
                    <img src="/NTLogo.png" alt="Diet with Sweta Logo" className="logo" />
                    <Paragraph style={{color : '#FFF'}}>If you are looking for a personalized diet plan tailored just for you, you're in the right place. At Diet with Sweta, we consider our nutrition advice to be transformative.</Paragraph>
                </div>
                <div className="links-section">
                    <h4 style={{marginBottom : '20px'}}>Quick Links</h4>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/services">Services</Link></li>
                        <li><Link to="/plans">Plans</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </div>
                <div className="help-section">
                    <h4  style={{marginBottom : '20px'}}>Help Links</h4>
                    <ul>
                        <li><a href="#">Privacy Policy</a></li>
                        <li><a href="#">Terms & Conditions</a></li>
                        <li><a href="#">FAQs</a></li>
                    </ul>
                </div>
                <div className="contact-section">
                    <h4  style={{marginBottom : '20px'}}>Opening Hours</h4>
                    <Paragraph>Mon-Sat: 9 AM – 6 PM</Paragraph>
                    <Paragraph  style={{marginBottom : '20px'}}>Sunday: Closed</Paragraph>
                    <h4  style={{marginBottom : '20px'}}> Location</h4>
                    <Paragraph>23K, Bade Raipur Rd, Shaktigarh, Jadavpur, Kolkata, West Bengal 700032</Paragraph>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 Diet with Sweta. All Rights Reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
