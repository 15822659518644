import React from 'react';
import { FaWhatsapp, FaPhoneAlt } from 'react-icons/fa';
import './IconBar.css'; // Create this CSS file for styling

const IconBar = () => {
  return (
    <div className="icon-bar">
      <a href="https://wa.me/918240755994?text=Hello!" target="_blank" rel="noopener noreferrer" className="whatsapp" aria-label="WhatsApp">

        <FaWhatsapp size={30} />
      </a>
      <a href="tel:+918240755994" className="phone" aria-label="Call">
        <FaPhoneAlt size={30} />
      </a>
    </div>
  );
};

export default IconBar;
