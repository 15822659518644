import React, { useState } from 'react';
import { sendEmail } from '../../utils/emailUtils'; 
import './ContactForm.css';
import HoverButton from '../UIComponents/HoverButton';
import { Paragraph, Title } from '../ReusableComponents/ReusableComponent';
import HoverCardButton from '../UIComponents/HoverCardButton';

// Center the map on your location
const center = {
  lat: 22.4918235,
  lng: 88.3660878
};

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission
    sendEmail(e, resetForm);
  };

  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      message: ''
    });
  };

  const handleImageClick = () => {
    // Open Google Maps with the center coordinates in a new tab
    window.open(`https://www.google.com/maps?q=${center.lat},${center.lng}`, '_blank');
  };

  return (
    <div className="contact-form-container">
      <div className="contact-form-content">
        <div className="contact-form-left">
          <Title style={{ paddingBottom:  '20px'}}>Get in Touch with Us!</Title>
          <Paragraph style={{ paddingBottom:  '30px'}}>We are here to help you with your nutritional needs. Whether you have questions, need advice, or want to schedule an appointment, feel free to reach out to us. Our team is ready to assist you on your journey to better health.</Paragraph>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              placeholder="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              placeholder="Mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              required
            />
            <textarea
              placeholder="Leave us a message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />

              <HoverCardButton 
                 type="submit"
                 style={{ width: '100%' }}
                 >
                  <span>  Send Message </span> 
                </HoverCardButton>
          </form>
        </div>
        <div className="contact-form-right">
          <img
            src="/address.png" // Path to the image in the public directory
            alt="Our Location"
            className="location-image"
            onClick={handleImageClick}
          />
          <div className="glass-overlay">
            <Paragraph>23K, Bade Raipur Rd, Shaktigarh, Jadavpur, Kolkata, West Bengal 700032</Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
