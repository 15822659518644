import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './FirstStep.css';
import HoverCardButton from '../UIComponents/HoverCardButton';
import { Title } from '../ReusableComponents/ReusableComponent';

const FirstStep = () => {
  const navigate = useNavigate(); // Initialize navigate function

  const handleBrowsePlansClick = () => {
    navigate('/plans'); // Navigate to /plans on button click
  };

  const handleContactMeClick = () => {
    navigate('/contact'); // Navigate to /contact on button click
  };

  return (
    <div className="first-step-container">
      <div className="first-step-content">
        <Title style={{marginBottom : '20px'}}>Are you ready to take the first step?</Title>
        <div className="first-step-buttons">
          <HoverCardButton onClick={handleBrowsePlansClick}>
            <span> Browse Plans </span>
            </HoverCardButton>
          <HoverCardButton onClick={handleContactMeClick}>
          <span>  Contact Me  </span>
            </HoverCardButton>
        </div>
      </div>
    </div>
  );
};

export default FirstStep;
