import React from 'react';
import { useParams } from 'react-router-dom';

import BlogPostHeader from '../components/Blog/BlogPost/BlogPostHeader';
import BlogPostContent from '../components/Blog/BlogPost/BlogPostContent';
import Sidebar from '../components/Blog/Posts/Sidebar';
import RelatedArticles from '../components/Blog/BlogPost/RelatedArticles';
import {blogDataArray} from '../components/constants'



const BlogPost = ({ infoData }) => {
  const { id } = useParams();
  const blogData = blogDataArray.find((post) => post.id === parseInt(id));

  if (!blogData) {
    return <div>Post not found</div>;
  }

  return (
    <div className="blog-post">
      <BlogPostHeader
        category={blogData.category}
        date={blogData.date}
        title={blogData.title}
        description={blogData.introduction}
        image={blogData.image}
      />
      <div className="blog-container">
        <div className="blog-content">
          <BlogPostContent blogData={blogData} />
        </div>
        <div className="sidebar">
          <Sidebar infoData={infoData} />
        </div>
      </div>
    </div>
  );
};

export default BlogPost;


/*
  return (
    <div className="blog-post">
      <BlogPostHeader
        category={post.category}
        date={post.date}
        title={post.title}
        description={post.description}
        image={post.image}
      />
      <div className="blog-post-main">
        <BlogPostContent
          author={post.author}
          authorImage={post.authorImage}
          authorBio={post.authorBio}
          content={post.content}
        />
        <Sidebar />
      </div>
      <RelatedArticles articles={relatedArticles} />
    </div>
  );
  */