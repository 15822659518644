import React from 'react';
import FirstStep from '../../components/About/FirstStep';
import ServicesComp from '../../components/Service/SeviceComp';

const Services = () => {
  return (
    <div>
   <ServicesComp />
      <FirstStep />
    </div>
  );
};

export default Services;
