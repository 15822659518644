import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import BlogCategory from './pages/BlogCategory';
import Plans from './pages/Plans';
import PlanSingle from './pages/PlanSingle';
import Contact from './pages/Contact';
import './App.css';
import PaymentPage from './pages/payment/PaymentPage';
import ScrollToTop from './components/ScrollToTop';
import Services from './pages/services/Services';
import ServiceDescription from './components/Service/ServiceDescription';
import Topbar from './components/Topbar/Topbar';
import IconBar from './components/IconBar'; 

function App() {
  return (
    <Router>
      <ScrollToTop /> 
      <Topbar/>
      <Navbar />
      <IconBar />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/category/:category" element={<BlogCategory />} />
          <Route path="/blog/post/:id" element={<BlogPost />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/:id" element={<ServiceDescription />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/plans/:planId" element={<PlanSingle />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/payment" element={<PaymentPage />} />  
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
