import React from 'react';
import ApproachCard from './ApproachCard';
import { approaches } from './constants'; // Import the approaches array
import './Approach.css';
import { Title } from './ReusableComponents/ReusableComponent';

const Approach = () => {
  return (
    <div className="approach-container">
      <Title>Our Approach</Title>
      
      <div className="approach-grid">
        {approaches.map((approach, index) => (
          <ApproachCard 
            key={index} 
            title={approach.title} 
            desc={approach.desc} 
            icon={approach.icon}
          />
        ))}
      </div>
    </div>
  );
};

export default Approach;
