import React from 'react';
import ValuesSection from '../components/About/ValuesSection';
import InstagramSection from '../components/About/InstagramSection';
import FirstStep from '../components/About/FirstStep';
import AboutExperienceSection from '../components/About/AboutExperienceSection';

const About = () => {
  return (
    <div>
      {/* <AboutMe/> */}
      <AboutExperienceSection/>
      <ValuesSection/>
      
      <InstagramSection/>
      <FirstStep/>
    </div>
  );
};

export default About;
