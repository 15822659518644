import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSeedling, 
  faGraduationCap, 
  faUtensils, 
  faCalendarAlt, 
  faDumbbell, 
  faBook, 
  faChartLine, 
  faConciergeBell, 
  faComments 
} from '@fortawesome/free-solid-svg-icons';
import './FeatureSection.css'; // Import the CSS file
import { Paragraph, Subtitle, Title } from './ReusableComponents/ReusableComponent';

const features = [
  {
    title: 'Personalized Nutrition Plans',
    icon: faSeedling,
    desc: 'Receive a tailored nutrition plan specifically designed for your body and goals. Our certified nutritionists will consider your unique needs, dietary preferences, and health conditions to craft a plan that suits you best.',
  },
  // {
  //   title: 'Guidance from Certified Nutritionists',
  //   icon: faGraduationCap,
  //   desc: 'Our team of experienced and certified nutritionists will provide professional guidance and support throughout your journey. They will answer your questions, address your concerns, and keep you motivated as you work towards your goals.',
  // },
  {
    title: 'Food Tracking and Analysis',
    icon: faUtensils,
    desc: 'Effortlessly track your food intake using our user-friendly app. Our nutritionists will analyze your data to provide insights into your eating habits, help you identify areas for improvement, and make personalized recommendations.',
  },
  {
    title: 'Meal Planning and Recipes',
    icon: faCalendarAlt,
    desc: 'Access a vast collection of delicious and healthy recipes tailored to your dietary needs. Our nutritionists will also create personalized meal plans, making it easier for you to stay on track and enjoy nutritious meals.',
  },
  {
    title: 'Lifestyle and Behavior Coaching',
    icon: faDumbbell,
    desc: 'Achieving sustainable results requires more than just a diet plan. Our nutritionists will work with you to develop healthy habits, address emotional eating, and provide strategies to overcome obstacles along the way.',
  },
  // {
  //   title: 'Nutritional Education and Workshops',
  //   icon: faBook,
  //   desc: 'Expand your knowledge of nutrition through informative articles and educational workshops. Our nutritionists will equip you with the knowledge and tools to make informed choices for long-term success.',
  // },
  {
    title: 'Regular Progress Monitoring',
    icon: faChartLine,
    desc: 'Stay on top of your progress with regular check-ins and adjustments to your nutrition plan. Our team will ensure you are on track to reach your goals.',
  },
  // {
  //   title: 'Customized Meal Plans',
  //   icon: faConciergeBell,
  //   desc: 'Our nutritionists will create meal plans specifically tailored to your taste preferences and dietary needs, making healthy eating easy and enjoyable.',
  // },
  {
    title: 'One-on-One Support',
    icon: faComments,
    desc: 'Receive personalized support from our team whenever you need it. We are here to help you overcome challenges and stay motivated on your journey.',
  },
];

const FeatureSection = () => (
  <div className="feature-section">
    <Title>Features</Title>
    <Paragraph style={{ marginBottom: '40px' }}>
      Welcome to the Feature Section of Nutritionist, your ultimate destination for all things nutrition and wellness.
    </Paragraph>
    <div className="feature-list">
      {features.map((feature, index) => (
        <div key={index} className="feature-item">
          <div className="icon">
            <FontAwesomeIcon icon={feature.icon} />
          </div>
          <Subtitle>{feature.title}</Subtitle>
          <Paragraph>{feature.desc}</Paragraph>
        </div>
      ))}
    </div>
  </div>
);

export default FeatureSection;
