import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PlanCard.css';
import { Title } from '../ReusableComponents/ReusableComponent';
import HoverCardButton from '../UIComponents/HoverCardButton';
import { contactInfo } from '../constants';


  


const PlanCard = ({ plan }) => {
  const navigate = useNavigate();

  // const handleViewDetails = () => {
  //   // navigate(`/plans/${plan.id}`);
  //   navigate(`/payment`);
  // };
  const handleViewDetails = () => {
    if (plan) {
      navigate('/payment', {
        state: {
          planTitle: plan.title,
          totalPrice: plan.price,
          phoneNumber: contactInfo.mobile, // Example phone number, adjust as needed
          description: plan.description,
          features: plan.features,
          image: plan.image,
        }
      });
    }
  };
 


  return (
    <div className="plan-card">
      <div >
        <Title style={{ padding: '20px'}}>{plan.title}</Title>
      </div>
      <div className="plan-info">
        {/* <p className="author">By {plan.author}</p> */}
        <p className="price"><strong>{plan.price}</strong></p>
        <ul className="features">
          {plan.features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
        <HoverCardButton style={{marginTop: '20px'}} onClick={() => handleViewDetails()} 
                 >
                  <span> Pay Now </span> 
         </HoverCardButton>
      </div>
    </div>
  );
};

export default PlanCard;
