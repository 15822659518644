import React from 'react';
import './BlogPostHeader.css';

const BlogPostHeader = ({ category, date, title, description, image }) => {
  return (
    <div className="blog-post-header">
      <div className="header-info">
        <span className="category">{category}</span>
        <span className="date">{date}</span>
        <h1 className="title">{title}</h1>
        <p className="description">{description}</p>
      </div>
      {/* <img src={image} alt={title} className="header-image" /> */}
    </div>
  );
};

export default BlogPostHeader;
