import emailjs from 'emailjs-com';

// EmailJS configuration
const serviceID = 'service_bu7otm8';  // Replace with your EmailJS service ID
const templateID = 'template_blaoby3'; // Replace with your EmailJS template ID
const userID = 'nky_FjxKneqHk656r';    // Replace with your EmailJS user ID

/**
 * Sends an email using EmailJS.
 * @param {Event} e - The form submission event.
 * @param {Function} resetForm - Function to reset the form state.
 */
export const sendEmail = (e, resetForm) => {
  e.preventDefault(); // Prevent default form submission

  emailjs.sendForm(
    serviceID,
    templateID,
    e.target,
    userID
  )
  .then((result) => {
      console.log(result.text);
      alert('Message Sent Successfully!');
      resetForm();  // Reset form state after successful submission
  }, (error) => {
      console.log(error.text);
      alert('Failed to send the message.');
  });
};
