import React, { useState } from 'react';
import './FAQ.css';
import { Paragraph, Subtitle, Title } from '../ReusableComponents/ReusableComponent';

const questions = [
  { question: 'How can I make an appointment?', answer: 'You can make an appointment by visiting our "Contact Us" page or calling our office directly at +91 8240755994.' },
  { question: 'What is the assessment cost?', answer: 'The cost for an initial assessment is ₹750, which includes a comprehensive evaluation and personalized nutrition plan.' },
  { question: 'Do you offer online appointments?', answer: 'Yes, we offer online appointments via Zoom for your convenience. Please contact us to schedule a virtual consultation.' },
  { question: 'Do you work with people with diabetes?', answer: 'Absolutely. We specialize in creating customized nutrition plans for individuals with diabetes to help manage their condition effectively.' },
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <Title style={{ marginBottom: '10px' }}>Frequently Asked Questions</Title>
      <Paragraph style={{ marginBottom: '30px' }}>We have compiled a list of frequently asked questions to help you understand our services better. If you have any other questions, feel free to contact us.</Paragraph>
      <div className="accordion">
        {questions.map((item, index) => (
          <div key={index} className="accordion-item">
            <div className="accordion-title" onClick={() => toggleAccordion(index)}>
              <Subtitle>{item.question}</Subtitle>
              <span>{activeIndex === index ? '-' : '+'}</span>
            </div>
            {activeIndex === index && <Paragraph style={{ padding: '20px', textAlign: 'left' }}>
  {item.answer}
</Paragraph>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
