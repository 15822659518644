import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import Logo from './Logo';
import HoverButton from './UIComponents/HoverButton';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const getLinkClass = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <nav className="navbar">
      <Link to="/" onClick={() => setMenuOpen(false)}>
        <Logo/>
      </Link>
      <div className="hamburger" onClick={toggleMenu} aria-label="Toggle menu">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <ul className={`navbar-menu ${menuOpen ? 'open' : ''}`}>
        <li><Link to="/" className={getLinkClass('/')} onClick={toggleMenu}>Home</Link></li>
        <li><Link to="/about" className={getLinkClass('/about')} onClick={toggleMenu}>About</Link></li>
        {/* <li><Link to="/blog" onClick={toggleMenu}>Blog</Link></li> */}
        <li><Link to="/services" className={getLinkClass('/services')} onClick={toggleMenu}>Services</Link></li>
        <li><Link to="/plans" className={getLinkClass('/plans')} onClick={toggleMenu}>Plans</Link></li>
        <li><Link to="/contact" className={getLinkClass('/contact')} onClick={toggleMenu}>Contact</Link></li>
        {/* <li>
          <HoverButton onClick={() => setMenuOpen(false)} style={{ margin: '0' }}>
            Contact Me
          </HoverButton>
        </li> */}
      </ul>
    </nav>
  );
};

export default Navbar;
