import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Hero.css'; // Import your Hero component CSS file
import HoverButton from './UIComponents/HoverButton'; // Import HoverButton component
import { Subtitle, Paragraph, Title } from './ReusableComponents/ReusableComponent';
import HoverCardButton from './UIComponents/HoverCardButton';

const Hero = () => {
  const navigate = useNavigate();

  const handleBrowsePlansClick = () => {
    navigate('/plans'); // Navigate to /plans on button click
  };

  const handleContactMeClick = () => {
    navigate('/contact'); // Navigate to /contact on button click
  };


  return (
    <div className="heros" style={{ backgroundImage: `url('/assets/Hero.png')` }}>
      <div className="heros-content"  style={{ paddingTop : '20px'}}>
      <Title style={{ paddingBottom : '20px'}}>
  Transform Your <span style={{ color: 'red' }}>❤️</span> Health with Expert Nutrition Guidance
</Title>
        <Paragraph>My virtual nutrition programs deliver the guidance, support, and accountability you need. Your health challenges are unique - your care should be too. Learn at your own pace, in one-on-one sessions or in a small group setting.</Paragraph>
        <div className="first-step-buttons">
       
        <HoverCardButton onClick={() => handleBrowsePlansClick()} >
         <span> Browse Plans  </span> 
        </HoverCardButton>

        <HoverCardButton onClick={() => handleContactMeClick()} >
        <span>  Contact Me  </span>
        </HoverCardButton>

        </div>
      </div>
    </div>
  );
};

export default Hero;
