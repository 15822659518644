import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ApproachCard.css'; // Import your ApproachCard component CSS file
import { Paragraph, Subtitle, Title } from './ReusableComponents/ReusableComponent';

const ApproachCard = ({ title, desc, icon }) => {
  return (
    <div className="approach-card">
      <div className="icon">
        <FontAwesomeIcon icon={icon} />
      </div>
      <Subtitle>{title}</Subtitle>
      <Paragraph>{desc}</Paragraph>
    </div>
  );
};

export default ApproachCard;
