import React from 'react';
import './NewsletterSubscription.css';

const NewsletterSubscription = () => {
  return (
    <section className="newsletter-subscription">
      <h2>Subscribe to our weekly email newsletter!</h2>
      <input type="email" placeholder="Enter your email" />
      <button>Subscribe</button>
    </section>
  );
};

export default NewsletterSubscription;
