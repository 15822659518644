import React from 'react';
import './Logo.css';
// import logo from './NTLogo.png'; // Update the path to your logo image
// import placeholder from './NTLogo.png'; // Update with path to your placeholder image

// const Logo = () => {
//   const handleImageError = (e) => {
//     e.target.src = placeholder; // Replace broken image with placeholder image
//     e.target.onerror = null; // Prevent infinite loop
//   };

//   return (
//     <div className="logo">
//       <img src={logo} alt="Logo" onError={handleImageError} />
//       <span className="logo-text">Dharavi Ranjan</span>
//     </div>
//   );
// };

const Logo = () => {
    return (
      <div className="logo">
        <img src={process.env.PUBLIC_URL + '/NTLogo.png'} alt="Logo" />
        <span className="logo-text"></span>
      </div>
    );
  };
  

export default Logo;
