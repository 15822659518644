import React, { useRef, useState, useEffect } from 'react';
import './Testimonial.css';
import { Paragraph, Title } from './ReusableComponents/ReusableComponent';

const testimonialsData = [
  {
    img: '/path/to/image1.jpg',
    text: 'I was diagnosed with high blood pressure and high cholesterol. Sweta Bose’s dietary plan and exercise recommendations helped me get both under control. I feel better than ever.',
    author: 'Ravi Kumar, Delhi, India',
  },
  {
    img: '/path/to/image2.jpg',
    text: 'Managing my diabetes seemed impossible until I started following Sweta Bose’s advice. My blood sugar levels are stable, and I feel more energetic.',
    author: 'Anita Sharma, Mumbai, India',
  },
  {
    img: '/path/to/image3.jpg',
    text: 'Losing weight has always been a challenge for me. With Sweta Bose’s guidance, I’ve lost 20 pounds and kept it off for over a year.',
    author: 'Amit Singh, Kolkata, India',
  },
  {
    img: '/path/to/image4.jpg',
    text: 'Dealing with PCOS was a nightmare until I found Sweta Bose. Her personalized diet plan made a huge difference. My symptoms have improved significantly.',
    author: 'Priya Desai, Chennai, India',
  },
  {
    img: '/path/to/image5.jpg',
    text: 'As a new mom, I wanted to make sure I was getting the right nutrients for myself and my baby. Sweta Bose’s lactation nutrition plan was perfect for me.',
    author: 'Sunita Rao, Hyderabad, India',
  },
  {
    img: '/path/to/image6.jpg',
    text: 'Living with arthritis is tough, but Sweta Bose’s therapeutic nutrition plan has helped me manage my pain and improve my mobility.',
    author: 'Rajesh Gupta, Bengaluru, India',
  },
];

const Testimonials = () => {
  const [currentPage, setCurrentPage] = useState(0);
  // const testimonialsPerPage = 3;
  const [testimonialsPerPage, setTestimonialsPerPage] = useState(3);


  const updateTestimonialsPerPage = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 599) {
      setTestimonialsPerPage(1); // 1 card per row on mobile
    } else if (screenWidth <= 767) {
      setTestimonialsPerPage(2); // 2 cards per row on tablets
    } else {
      setTestimonialsPerPage(3); // 3 cards per row on larger screens
    }
  };

  useEffect(() => {
    updateTestimonialsPerPage();
    window.addEventListener('resize', updateTestimonialsPerPage);

    return () => window.removeEventListener('resize', updateTestimonialsPerPage);
  }, []);


  const totalPages = Math.ceil(testimonialsData.length / testimonialsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const currentTestimonials = testimonialsData.slice(
    currentPage * testimonialsPerPage,
    (currentPage + 1) * testimonialsPerPage
  );

  return (
    <div className="testimonials">
      <div className="testimonial-header">
        <Title>What Our Clients Are Saying</Title>
      </div>
      <div className="testimonial-container">
        <div className="testimonial-grid">
          {currentTestimonials.map((testimonial, index) => (
            <div className="testimonial-card" key={index}>
              <div className="testimonial-content">
                <Paragraph style={{ marginBottom: '10px' }} >{testimonial.text}</Paragraph>
                <Paragraph style={{ marginBottom: '10px' }} >{testimonial.author}</Paragraph>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pagers">
        <button
          onClick={handlePreviousPage}
          className="scroll-button left"
          disabled={currentPage === 0}
        >
          &lt;
        </button>
        <div className="dots-container">
          {[...Array(totalPages)].map((_, index) => (
            <span
              key={index}
              className={`dot ${currentPage === index ? 'active' : ''}`}
              onClick={() => setCurrentPage(index)}
            ></span>
          ))}
        </div>
        <button
          onClick={handleNextPage}
          className="scroll-button right"
          disabled={currentPage === totalPages - 1}
        >
          &gt;
        </button>

        {/* <HoverButton
      onClick={handleNextPage}
      disabled={currentPage === totalPages - 1}
      style={{ marginLeft: '10px' }}  
    >
      &gt;
    </HoverButton> */}

      </div>
    </div>
  );
};

export default Testimonials;