import React from 'react';
import { Route, Routes } from 'react-router-dom';
import FirstStep from '../components/About/FirstStep';
import NutritionPlans from '../components/Plans/NutritionPlans';
import PlanSingle from './PlanSingle';
import OnlinePlans from '../components/Plans/OnlinePlans';
import HomeVisitPlans from '../components/Plans/HomeVisitPlans';

const Plans = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<NutritionPlans />} />
        <Route path="/plan/:id" element={<PlanSingle />} />
      </Routes>
      <OnlinePlans />
      <HomeVisitPlans />
      {/* <FirstStep /> */}
    </div>
  );
};

export default Plans;
