import React from 'react';
import './DashServices.css';
import { serviceData } from './constants'; // Importing service data
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import { Paragraph, Subtitle, Title } from './ReusableComponents/ReusableComponent';
import HoverCardButton from './UIComponents/HoverCardButton';

const DashServices = () => {
    const navigate = useNavigate(); // Initialize the hook

    const handleViewMore = () => {
        navigate('/services'); // Navigate to the services page when clicked
    };

    return (
        <div className="dash-services-container">
            <Title style={{ paddingTop: '30px' }}>Our Services</Title>
            <div className="dash-services-cards">
                {serviceData.slice(0, 3).map((service, index) => (
                    <div key={index} className="dash-service-card" style={{ '--animation-order': index + 1 }}>
                        <img src={service.image} alt={service.title} className="dash-service-image" />
                        <div className="dash-service-content">
                            <Subtitle>{service.title}</Subtitle>
                            <Paragraph>{service.description}</Paragraph>
                        </div>
                    </div>
                ))}
            </div>
            {/* <button className="dash-view-more-btn" onClick={handleViewMore}>View More</button> */}
            <HoverCardButton onClick={() => handleViewMore()} >
           <span> View More </span> 
        </HoverCardButton>
        </div>
    );
};

export default DashServices;
