const BlogPostContent = ({ blogData }) => {
  return (
    <div className="blog-post-content">
      <h1 className="title">{blogData.title}</h1>
      <p className="introduction">{blogData.introduction}</p>
      
      {blogData.content.map((section, index) => (
        <div key={index} className="section">
          <h2 className="section-title">{section.heading}</h2>
          <p className="section-content">{section.text}</p>
          {section.image && <img src={section.image} alt={section.heading} className="section-image" />}
        </div>
      ))}

      <h2 className="meal-plan-title">Sample Meal Plan</h2>
      <div className="meal-plan">
        {blogData.mealPlan.map((meal, index) => (
          <div key={index} className="meal">
            <h3 className="meal-name">{meal.name}</h3>
            <p className="meal-description">{meal.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogPostContent;
