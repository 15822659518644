import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import './Sidebar.css';
import { infoData } from '../../constants';

const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="author-info">
        <div className="author-avatar">
          <img
            src={infoData.image}
            alt={infoData.name}
            className="author-image"
          />
        </div>
        <h3 className="author-name">{infoData.name}</h3>
        <div className="author-role">{infoData.role}</div>
        <p className="author-description">
          {infoData.description}
        </p>
      </div>
      <div className="social-links">
        {infoData.socialLinks.map((link, index) => (
          <a key={index} href={link.url} target="_blank" rel="noopener noreferrer" className="social-icon">
            {link.icon}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;