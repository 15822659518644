import React from 'react';
import './HoverCardButton.css'; 

const HoverCardButton = ({ children, onClick, style }) => {
  return (
    <button className="hover-card-button" onClick={onClick} style={style}>
      {children}
    </button>
  );
};

export default HoverCardButton;
