import React from 'react';
import './InstagramSection.css';
import { useNavigate } from 'react-router-dom';


import { Paragraph, Title } from '../ReusableComponents/ReusableComponent';
import HoverCardButton from '../UIComponents/HoverCardButton';


const InstagramSection = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    // navigate('/plans');
  };

  return (
    <section className="instagram-section">
      <div className="instagram-content">
        <Title style={{marginBottom : '20px'}}>Follow Me on Instagram</Title>
        <Paragraph style={{marginBottom : '30px'}}>Stay updated with the latest nutrition tips, healthy recipes, and motivational content. Join our community and embark on a journey towards a healthier you.</Paragraph>
        <div className="instagram-images">
        <img src={"/insta1.png"} alt="Healthy Meal" />
          <img src={"/insta2.png"} alt="Workout Routine" />
          <img src={"/insta3.png"} alt="Client Success" />
          <img src={"/insta4.png"} alt="Nutrition Tips" />
          <img src={"/insta5.png"} alt="Relaxation Time" />
        </div>
        {/* <button className="instagram-button">@nutritionist</button> */}
        
        <HoverCardButton
  href="#"
  onClick={(e) => {
    e.preventDefault(); // Prevent default link behavior
    handleButtonClick(); // Your custom logic (e.g., logging)
    window.open("https://www.instagram.com/diet_with_sweta/", "_blank");
  }}
>
<span> @nutritionist </span>
</HoverCardButton>

       
      </div>
    </section>
  );
};

export default InstagramSection;
