import React from 'react';
import './Webinar.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';
import { Paragraph, Title } from './ReusableComponents/ReusableComponent';
import HoverCardButton from './UIComponents/HoverCardButton';

const Webinar = () => {

  const navigate = useNavigate(); // Initialize navigate function


  const handleButtonClick = () => {
    navigate('/plans');
  };

  return (
    <div className="cta">
      <div className="cta-content">
        <div className="cta-text">
          <Title style={{ marginBottom: '20px' }}>Are You Ready to Take the First Step?</Title>
          <Paragraph style={{ marginBottom: '30px' }}>
          Embark on a transformative journey where 
          expert guidance, personalized nutrition plans,
           and unwavering support empower you to achieve your wellness goals.
            This is your moment to embrace positive change, nurture your body, 
            and unlock the vibrant health you deserve. 
            Let's make your well-being a priority—starting today.
          </Paragraph>
          {/* <button>Get Started</button> */}

          <HoverCardButton onClick={() => handleButtonClick()}>
          <span> Get Started </span>
        </HoverCardButton>
        </div>
        <div className="cta-image">
          <img src="./webinar.png" alt="Webinar" />
        </div>
      </div>
    </div>
  );
};

export default Webinar;
