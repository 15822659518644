import React, { useEffect, useState, useRef } from 'react';
import './LatestPostsSection.css';
import LatestPostsHeader from './LatestPostsHeader';
import PostCard from './PostCard';
import Sidebar from './Sidebar';
import { blogDataArray, infoData } from '../../constants';

// SidebarStates.js
export const SidebarStates = {
  STICKY: 'sticky',
  SCROLL: 'scroll',
};

const LatestPostsSection = () => {
  const [sidebarPosition, setSidebarPosition] = useState(SidebarStates.SCROLL);
  const firstPostRef = useRef(null);
  const lastPostRef = useRef(null);
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      const viewportHeight = window.innerHeight;

      if (firstPostRef.current && lastPostRef.current && sidebarRef.current) {
        const firstPostOffset = firstPostRef.current.getBoundingClientRect().top + window.scrollY;
        const lastPostOffset = lastPostRef.current.getBoundingClientRect().bottom + window.scrollY;
        const sidebarHeight = sidebarRef.current.offsetHeight;

        if (offset + viewportHeight > lastPostOffset) {
          // Stick to bottom
          setSidebarPosition(SidebarStates.SCROLL);
          sidebarRef.current.style.position = 'absolute';
          sidebarRef.current.style.bottom = '0';
          sidebarRef.current.style.top = 'auto';
        } else if (offset > firstPostOffset && offset + sidebarHeight < lastPostOffset) {
          // Stick to top
          setSidebarPosition(SidebarStates.STICKY);
          sidebarRef.current.style.position = 'fixed';
          sidebarRef.current.style.top = '0';
          sidebarRef.current.style.bottom = 'auto';
        } else {
          // Scroll normally
          setSidebarPosition(SidebarStates.SCROLL);
          sidebarRef.current.style.position = 'relative';
          sidebarRef.current.style.top = 'auto';
          sidebarRef.current.style.bottom = 'auto';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section className="latest-posts-section">
      <LatestPostsHeader />
      <div className="latest-posts-content">
        <div className="posts-list">
          {blogDataArray.map((post, index) => (
            <PostCard
              key={index}
              id={post.id}
              image={post.image}
              category={post.category}
              date={post.date}
              title={post.title}
              description={post.introduction}
              ref={
                index === 0 ? firstPostRef : index === blogDataArray.length - 1 ? lastPostRef : null
              }
            />
          ))}
          
        </div>
        <div className="sidebar-container">
          <div className={`sidebar-content ${sidebarPosition === SidebarStates.STICKY ? 'sticky' : ''}`} ref={sidebarRef}>
            <Sidebar infoData={infoData} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LatestPostsSection;
