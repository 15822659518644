import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import './PostCard.css';

const PostCard = forwardRef(({ id, image, category, date, title, description }, ref) => {
  const postData = { id, image, category, date, title, description };

  return (
    <div className="post-card" ref={ref}>
      <Link to={`/blog/post/${id}`} state={{ postData }}>
        {/* <img src={image} alt={title} className="post-image" /> */}
        <div className="post-content">
          <span className="post-category">{category}</span>
          <span className="post-date">{date}</span>
          <h2 className="post-title">{title}</h2>
          <p className="post-description">{description}</p>
        </div>
      </Link>
    </div>
  );
});

export default PostCard;
