import React from 'react';
import './ServiceComp.css';
import { serviceData } from '../constants';
import { useNavigate } from 'react-router-dom';
import HoverButton from '../UIComponents/HoverCardButton';
import { Paragraph, Subtitle, Title } from '../ReusableComponents/ReusableComponent';
import HoverCardButton from '../UIComponents/HoverCardButton';


const ServicesComp = () => {
    const navigate = useNavigate();

    const handleReadMore = (id) => {
        navigate(`/services/${id}`); // Navigate to the service description page
    };

    const firstRow = serviceData.slice(0, 3);
    const secondRow = serviceData.slice(3, 6);
    const thirdRow = serviceData.slice(6, 8);

    return (
        <div className="services-container">
            <Title style={{ paddingTop: '30px' }}>Our Services</Title>
            <Paragraph style={{ padding: '20px'}}>
        At our core, we believe that nutrition plays a vital role in leading a healthy and balanced life. 
        Our diverse range of services is designed to cater to the unique health needs of every individual. 
        Whether you're seeking expert guidance on managing chronic conditions, improving your overall wellness, 
        or finding personalized dietary solutions, our team of professionals is here to support you every step 
        of the way. Explore our tailored programs that focus on empowering you through science-backed nutrition 
        and compassionate care, helping you achieve sustainable health goals and a better quality of life.
    </Paragraph>
            <div className="services-row">
                {firstRow.map((service, index) => (
                    <div key={index} className="service-card">
                        <img src={service.image} alt={service.title} className="service-image" />
                        <Subtitle style={{ paddingBottom:  '20px'}}>{service.title}</Subtitle>
                        <Paragraph style={{ paddingBottom:  '20px'}}>{service.description}</Paragraph>
                        {/* <button className="read-more-btn" onClick={() => handleReadMore(service.id)}>
                            Read More
                        </button> */}
                         <HoverCardButton onClick={() => handleReadMore(service.id)}>
                         <span>Read More</span>
                         </HoverCardButton>
                    </div>
                ))}
            </div>

            <div className="services-row">
                {secondRow.map((service, index) => (
                    <div key={index} className="service-card">
                        <img src={service.image} alt={service.title} className="service-image" />
                        <Subtitle style={{ paddingBottom:  '20px'}}>{service.title}</Subtitle>
                        <Paragraph style={{ paddingBottom:  '20px'}}>{service.description}</Paragraph>
                        {/* <button className="read-more-btn" onClick={() => handleReadMore(service.id)}>
                            Read More
                        </button> */}
                         <HoverCardButton onClick={() => handleReadMore(service.id)}>
                         <span>Read More</span>
                         </HoverCardButton>
                    </div>
                ))}
            </div>

            <div className="services-row">
                {thirdRow.map((service, index) => (
                    <div key={index} className="service-card">
                        <img src={service.image} alt={service.title} className="service-image" />
                        <Subtitle style={{ paddingBottom:  '20px'}}>{service.title}</Subtitle>
                        <Paragraph style={{ paddingBottom:  '20px'}}>{service.description}</Paragraph>
                        {/* <button className="read-more-btn" onClick={() => handleReadMore(service.id)}>
                            Read More
                        </button> */}
                         <HoverCardButton onClick={() => handleReadMore(service.id)}>
                         <span>Read More</span>
                         </HoverCardButton>

                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServicesComp;
