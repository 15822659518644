// src/components/NutritionPlans.js
import React from 'react';
import OnlinePlans from './OnlinePlans';
import HomeVisitPlans from './HomeVisitPlans';
import './NutritionPlans.css';
import { Title } from '../ReusableComponents/ReusableComponent';

const NutritionPlans = () => {
  return (
    <div className="nutrition-plans-container">
      <Title  style={{marginBottom : '20px'}}>Nutrition Plans</Title>
      {/* <p className="intro">
        Discover the perfect plan tailored to your needs. Whether you're starting out or looking for comprehensive support, our plans offer personalized guidance to help you achieve your health goals.
      </p> */}
    </div>
  );
};

export default NutritionPlans;
