// src/components/HomeVisitPlans.js
import React from 'react';
import PlanCard from './PlanCard';
import './Plans.css'; // Reuse the same CSS file for both components
import { homeVisitPlans } from '../constants';
import { Paragraph, Subtitle, Title } from '../ReusableComponents/ReusableComponent';

const HomeVisitPlans = () => {
  return (
    <div className="plans-section">
      <Subtitle  style={{marginBottom : '20px'}}>Home Visit Consultation Service Plans</Subtitle>
      <Paragraph>
        Discover our home visit consultation service plans, where our nutritionist provides in-person guidance at your location. 
        These plans are designed for those who prefer a more hands-on approach, with personalized advice delivered directly to your door.
      </Paragraph>
      <div className="service-plans">
        {homeVisitPlans.map((plan) => (
          <PlanCard key={plan.id} plan={plan} />
        ))}
      </div>
    </div>
  );
};

export default HomeVisitPlans;
