import React from 'react';
import './LatestPostsHeader.css';

const LatestPostsHeader = () => {
  return (
    <div className="latest-posts-header">
      <h2>Latest posts</h2>
    </div>
  );
};

export default LatestPostsHeader;
